import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MEDIA_BASE_URL } from "../../utils/constants";
import moment from "moment";
import { IoMdClose } from "react-icons/io";
import defaultUser from "../../assets/defaultProfile.png";

function calculateAge(birthDate) {
  const today = moment();
  const birth = moment.unix(birthDate);
  const age = today.diff(birth, "years");
  return age;
}

export default function UserDataMoodle({ setModalData, modelData }) {
  const hadleModelClose = () => {
    setModalData({ showModel: false, data: {} });
  };

  const cancelButtonRef = useRef(null);

  const {
    profile_picture,
    name,
    gender,
    interested_gender,
    zodiac_sign,
    date_of_birth,
    email,
  } = { ...modelData?.data };

  return (
    <Transition.Root show={modelData?.showModel} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={hadleModelClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
                <div className="relative bg-white p-4">
                  <div className="relative bg-white px-4 pb-6 pt-5 sm:px-10 sm:py-10 sm:pb-4">
                    <div className="max-w-sm mx-auto">
                      <div className="mt-3 text-center sm:text-left">
                        <div className="rounded-2xl relative">
                          <img
                            src={
                              profile_picture
                                ? `${MEDIA_BASE_URL}${profile_picture}`
                                : defaultUser
                            }
                            alt=""
                            className="h-64 m-auto w-full rounded-xl z-[2] relative border-2  border-black"
                          />
                          <div className="absolute -bottom-24 h-48 bg-[#d5f5ea] w-auto -mt-32 -left-4 -right-4 z-0 rounded-2xl border-r-[5px] border-t-[3px] border-b-[5px] border-l-[3px] border-black"></div>
                        </div>
                        <div className="-top-14 mx-5 relative w-auto border-2 rounded-xl border-black bg-white z-10 pt-4 pb-3 px-2">
                          <div className="flex items-center justify-between">
                            <div className="flex-1">
                              <h4 className="text-2xl"> {name}</h4>
                              <p className="break-all">{email}</p>
                              <p className="font-bold capitalize">{gender}</p>
                            </div>
                            <div className="h-10 w-10 bg-red-600 rounded-full flex justify-center items-center border-2 border-black">
                              {calculateAge(date_of_birth)}
                            </div>
                          </div>
                        </div>
                        <div className="mb-2">
                          <p class="py-1">
                            <b>Date of Birth:</b>
                            <span className="capitalize ml-2">
                              {moment
                                .unix(date_of_birth)
                                .format("DD/MM/YYYY") ?? "N/A"}
                            </span>
                          </p>

                          <p class="py-1">
                            <b>Interested Gender:</b>
                            <span className="capitalize ml-2">
                              {interested_gender ?? "N/A"}
                            </span>
                          </p>
                          <p class="py-1">
                            <b>Zodiac Sign:</b>
                            <span className="capitalize ml-2">
                              {zodiac_sign ?? "N/A"}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="absolute right-2 top-4 z-20">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white p-2.5 font-semibold text-gray-900 shadow-sm border border-r-2 border-b-2 border-black hover:bg-gray-50 sm:mt-0 sm:w-auto text-lg"
                      onClick={hadleModelClose}
                      ref={cancelButtonRef}
                    >
                      <IoMdClose />
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
