import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/axios";
import MapComponent from "./MapComponent";
import { MAPS_PER_PAGE_DATA_LIMIT } from "../../utils/constants";
import { useStore } from "../../contexts/StoreProvider";

const MapPage = () => {
  const [users, setUSers] = React.useState([]);
  const [showProfiles, setShowProfiles] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const { setLoading } = useStore();

  const getUsersData = async (page) => {
    setLoading(true);
    let response = await axiosInstance.get(`/users`, {
      params: {
        page: page || currentPage,
        limit: MAPS_PER_PAGE_DATA_LIMIT,
      },
    });
    setLoading(false);
    if (response.success) {
      setUSers(response.data);
      setCurrentPage(Number(response?.currentPage));
      setTotalPages(Number(response?.totalPages));
    } else {
      toast.error(response?.message);
    }
  };

  React.useLayoutEffect(() => {
    getUsersData();
  }, []);

  return (
    <div>
      <div className="">
        <div>
          <h1 class="text-4xl font-semibold">All Users Location</h1>
        </div>
        <div className="flex items-center justify-between">
        <label className="flex items-center cursor-pointer">
          <input
            type="checkbox"
            defaultValue=""
            className="sr-only peer"
            value={showProfiles}
            onChange={() => setShowProfiles(!showProfiles)}
          />
          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
          <span className="ms- ml-2 text-sm font-medium text-gray-900">
            Show Profiles
          </span>
        </label>

        {/* pagination ================================================================= */}
        {totalPages > 1 && (
          <div className="flex items-center justify-center flex-col">
            <nav aria-label="Page navigation example" className="mt-3">
              <ul class="inline-flex -space-x-px">
                <li>
                  <button
                    class={`px-3 py-2 ml-0 leading-tight bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 text-gray-700 ${
                      currentPage === 1 && "!bg-gray-100 hover:bg-gray-300"
                    }`}
                    disabled={currentPage === 1}
                    onClick={() => {
                      getUsersData(parseInt(currentPage) - 1);
                    }}
                  >
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages })?.map((_, i) => {
                  return (
                    <li key={i}>
                      <button
                        class={`px-3 py-2 leading-tight bg-white border border-gray-300 hover:bg-gray-100 text-gray-700 ${
                          currentPage === i + 1 &&
                          "!bg-gray-300 hover:bg-gray-300"
                        }`}
                        onClick={() => {
                          getUsersData(i + 1);
                        }}
                        disabled={currentPage === i + 1}
                      >
                        {i + 1}
                      </button>
                    </li>
                  );
                })}
                <li>
                  <button
                    class={`px-3 py-2 ml-0 leading-tight bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 text-gray-700 ${
                      currentPage === totalPages &&
                      "!bg-gray-100 hover:bg-gray-300"
                    }`}
                    disabled={currentPage === totalPages}
                    onClick={(e) => {
                      if (currentPage !== totalPages)
                        getUsersData(parseInt(currentPage) + 1);
                    }}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
            {/* <span class="text-sm text-gray-700 mt-1">
              Showing{" "}
              <span class="font-semibold text-gray-700">
                {(parseInt(currentPage) - 1) * MAPS_PER_PAGE_DATA_LIMIT}
              </span>{" "}
              to{" "}
              <span class="font-semibold text-gray-700">
                {parseInt(currentPage) * MAPS_PER_PAGE_DATA_LIMIT}
              </span>{" "}
              of{" "}
              <span class="font-semibold text-gray-700">
                {parseInt(totalPages) * MAPS_PER_PAGE_DATA_LIMIT}
              </span>{" "}
              Entries
            </span> */}
          </div>
        )}
        {/* Pagination ========> */}

        </div>
      </div>
      <div className="mt-5">
        <MapComponent usersData={users} showProfiles={showProfiles} />
      </div>
    </div>
  );
};

export { MapPage };
