// export const API_BASE_URL = "http://199.192.31.20:8000/api/v1/";
// export const MEDIA_BASE_URL = "http://199.192.31.20:8000";

// export const API_BASE_URL = "https://199.192.31.20:8000/api/v1/";
// export const MEDIA_BASE_URL = "https://199.192.31.20:8000";

export const API_BASE_URL = "https://www.spiritualdatingapp.com/api/v1";
export const MEDIA_BASE_URL = "https://www.spiritualdatingapp.com";
export const API_GOOGLE_MAP_KEY = "";

export const PER_PAGE_DATA_LIMIT = 10;
export const LocalStorageKeys = {
  LOGGED_IN: "LoggedIn",
  USER: "user",
  TOKEN: "token",
};
export const MAPS_PER_PAGE_DATA_LIMIT = 100;
