import React, { useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { API_GOOGLE_MAP_KEY, MEDIA_BASE_URL } from "../../utils/constants";
import UserDataMoodle from "./UserDataMoodle";
import defaultUser from "../../assets/defaultProfile.png";

const containerStyle = {
  width: "83vw",
  height: "100vh",
};

const center = {
  lat: 0,
  lng: 0,
};

const MapComponent = ({ usersData, showProfiles }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_GOOGLE_MAP_KEY,
  });

  const [modelData, setModalData] = useState({ showModel: false, data: {} });

  return (
    <>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={2.5}
          options={{
            minZoom: 2.5,
          }}
        >
          {usersData.map((curData, index) => {
            const { location, profile_picture } = curData;
            const iconObj = {
              url: profile_picture
                ? `${MEDIA_BASE_URL}${profile_picture}`
                : defaultUser,
              scaledSize: new window.google.maps.Size(25, 25),
            };
            return (
              <Marker
                key={index}
                position={{
                  lat: location.coordinates?.[1],
                  lng: location.coordinates?.[0],
                }}
                icon={showProfiles ? iconObj : ""}
                onClick={() => {
                  setModalData({ showModel: true, data: curData });
                }}
              >
                <div
                  style={{
                    width: 20,
                    height: 20,
                    backgroundColor: "red",
                    borderRadius: "50%",
                  }}
                ></div>
              </Marker>
            );
          })}

          <UserDataMoodle setModalData={setModalData} modelData={modelData} />
        </GoogleMap>
      )}
    </>
  );
};

export default MapComponent;
